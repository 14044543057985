


















/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { getCompetedMallByUserId } from '@/services/api';
type competed = {
  label: string,
  value: number,
};
@Component({
  components: {
  },
})

export default class CompetedRegionList extends Vue {
  competedModel = 0;
  @Prop({
    type: Number,
    required: false,
    default: 0,
  })
  mallId!: 0;
  competitionList: any = [];
  initMallId = 0;
  competedModelObj: any = {};
  @Watch('mallId')
  changeDateTypeStatus(mallId: number): void {
    if (this.initMallId !== mallId) {
      this.getCompetedData();
      this.initMallId = mallId;
    }
  }

  // 获取竞品
  async getCompetedData(): Promise<void> {
    const params = {
      mall_id: this.mallId,
    };
    const competedRes = await getCompetedMallByUserId(params);
    const { data } = competedRes;
    const mallId = data && data.length !== 0 ? Number(data[0].mall_id) : 0;
    const competitionList = data ? data : [];
    const competedModel = mallId;
    const obj = {
      label: '',
      value: mallId,
    };
    this.competitionList = competitionList;
    this.competedModel = competedModel;
    this.competedModalChange(obj);
  }

  // 竞品changer
  competedModalChange(e: competed): void{
    const params = e ? e : {
      label: '',
      value: 0,
    };
    const { value } = params;
    const nMallId = value;
    this.competedModel = nMallId;
    let obj = {};
    this.competitionList.forEach((item: any) => {
      const { mall_id: mallId } = item;
      if (nMallId === mallId) {
        obj = item;
      }
    });
    const nCount = this.competitionList.length;
    this.$emit('competedModalChange', obj, nCount);
  }
}
