



















/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { getMallByUserId } from '@/services/sourceApi';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
type MallObj = {
  value: string;
  label: string;
  children: any;
};

@Component({
  components: {
  },
})

export default class RegionList extends Vue {
  mallOptions = [];
  value: string[] = [];
  mallId = 0;
  $stores: any;
  @Prop({
    type: String,
    required: false,
    default: '',
  })
  text!: string;
  $refs: any;
  async mounted(): Promise<any> {
    const mallData = await this.getMallByUserId();
    const { mallInfo, option, value } = mallData;
    this.mallOptions = option;
    this.value = value;
    this.updateMallInfo(mallInfo);
    this.$emit('mallChangeHandler', mallInfo);
  }

  MallChangeHandler(e: string[]): void {
    const mallId = parseInt(e[2].split('-')[0] || '', 10);
    if (this.mallId !== mallId) {
      this.mallId = mallId;
      const e_copy = [...e];
      e_copy.shift();
      e_copy.shift();
      const mallinfo_str = e_copy.join(',');
      this.value = [e[0], e[1], mallinfo_str];
      const mallInfos = JSON.parse(mallinfo_str.split('-')[1]);
      this.updateMallInfo(mallInfos);
      this.$emit('mallChangeHandler', mallInfos);
    }
  }

  async updateMallInfo(mallInfos: any): Promise<void> {
    const {
      mall_id: mallId,
      boundary_gcj: boundary,
      center_gcj: center,
      status,
      adcode,
      name,
    } = mallInfos;
    this.$stores.mall.setMallId(mallId);
    this.$stores.mall.setMallName(name);
    this.$stores.mall.setStatus(status);
    this.$stores.mall.setCenter(center);
    this.$stores.mall.setBoundary(boundary);
    this.$stores.mall.setAdcode(adcode);
    const { state } = this.$store;
    sessionStorage.setItem('store', JSON.stringify(state));
  }

  async getMallByUserId(): Promise<any> {
    const res: any = await getMallByUserId();
    const datas: any = Object.values(res.data);
    const mallObj: any = {};
    const initObj: any = datas[0];
    let { mallId } = this.$store.state.mall;
    mallId = mallId ? mallId : initObj.mall_id;
    let mallInfo: any = {};
    datas.forEach((item: any) => {
      const {
        mall_id,
        name,
        province,
        city,
        status,
        boundary_gcj,
        center_gcj,
        adcode,
        compete_count,
        district,
      } = item;
      if (!mallObj[province]) {
        mallObj[province] = {};
      }
      if (!mallObj[province][city]) {
        mallObj[province][city] = {};
      }
      const itemInfo = {
        mall_id,
        name,
        status,
        boundary_gcj,
        center_gcj,
        adcode,
        province,
        city,
        compete_count,
        district,
      };
      if (mallId === mall_id) {
        mallInfo = itemInfo;
      }
      mallObj[province][city][mall_id] = itemInfo;
    });
    const mallInfoAry = Object.values(mallInfo);
    const mallObjs = [];
    // eslint-disable-next-line
    for (const province in mallObj) {
      const cityItem = mallObj[province];
      const provinceObj: MallObj = {
        value: province,
        label: province,
        children: [],
      };
      // eslint-disable-next-line
      for (const city in cityItem) {
        const mallItem = cityItem[city];
        const cityObj: MallObj = {
          value: city,
          label: city,
          children: [],
        };
        // eslint-disable-next-line
        for (const mall_id in mallItem) {
          const malls = mallItem[mall_id];
          const malls2 = JSON.stringify(malls);
          const mallObj = {
            value: `${mall_id}-${malls2}`,
            label: malls.name,
          };
          cityObj.children.push(mallObj);
          if (mallInfoAry.length === 0) {
            mallInfo = malls;
          }
        }
        provinceObj.children.push(cityObj);
      }
      mallObjs.push(provinceObj);
    }

    const { province, mall_id: mallIds, city } = mallInfo;
    const mall = `${mallIds}-${JSON.stringify(mallInfo)}`;
    const value = [province, city, mall];
    return {
      option: mallObjs,
      value,
      mallInfo,
    };
  }

  @Watch('$route')
  changeRouter(): void{
    this.$refs.regionSelect.visible = false;
  }
}
