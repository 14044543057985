






















import { Vue, Component, Prop } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class NavMenu extends Vue {
  @Prop({
    type: String,
    required: false,
    default: '',
  })
  currentValue!: '';
  @Prop({
    type: Number,
    required: false,
    default: 1,
  })
  type!: number;
  @Prop({
    type: Array,
    required: false,
    default: [],
  })
  menuList!: any;
  @Prop({
    type: String,
    required: false,
    default: 'dark',
  })
  theme!: string;
  unqireVal = '';
  selectChangeHandler(val: string): void {
    if (this.unqireVal === val) return;
    this.unqireVal = val;
    if (this.type === 1) {
      this.$router.push(val);
      sessionStorage.setItem('manageRouterPath', val);
      this.$emit('selectChangeHandler', val);
    } else if (this.type === 2) {
      const routerObj = this.routerChangeHandler(val);
      const { parentObj, childrenList } = routerObj;
      const pathAry = val.split('/');
      const paths = pathAry[2];
      const sessionPath = sessionStorage.getItem(`${paths}RouterPath`);
      let { path } = childrenList[0];
      if (childrenList[0].children && childrenList[0].children.length > 0) {
        path = childrenList[0].children[0].path;
      }
      const path2 = sessionPath ? sessionPath : path;
      const currentPath = val;
      const cCurrentPath = path2;
      const path2Ary = path2.split('/');
      const openName = [`${path2Ary[0]}/${path2Ary[1]}/${path2Ary[2]}/${path2Ary[3]}`];
      sessionStorage.setItem('initSystemPath', path2);
      this.$router.push(path2);
      const obj = {
        childrenList,
        currentPath,
        cCurrentPath,
        openName,
        parentObj,
        name: parentObj.name,
      };
      this.$emit('selectChangeHandler', obj);
    }
  }

  routerChangeHandler(paths: string): any {
    let childrenList: any = [];
    let parentObj: any = {};
    this.menuList.forEach((item: any) => {
      const { path, children } = item;
      if (path === paths) {
        childrenList = children;
        parentObj = item;
      }
    });
    return {
      parentObj,
      childrenList,
    };
  }
}
