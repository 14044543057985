








































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { initDate } from '@/components/index';
import exportJsonDataExcel from '@/utils/exportToExcel';
import regionList from '@/components/regionList/index.vue';
import competedRegionList from '@/components/competedRegionList/index.vue';
import { permissionModule } from '@/store/modules/permission';
import cityList from '@/components/cityList/index.vue';
import { getMallServiceStartAndEnd } from '@/services/systemApi';
import { startOfWeek, endOfWeek } from '@/utils/index';


type DateObj = {
  type: string,
  computed: number,
  dateInit: string,
  aoiPortraitDate: string,
};

let services: any = {
  dates: [],
  mallId: '',
  isTestUser: false,
};


@Component({
  components: {
    regionList,
    cityList,
    competedRegionList,
  },
})


/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export default class SecondaryMenu extends Vue {
  $refs: any;
  $dayjs: any;
  $stores: any;
  isCheck = (this as any).$store.state.global.isChecked;
  returnName = (this as any).$store.state.global.name;
  @Prop({
    type: Number,
    required: false,
    default: '0',
  })
  routerId!: number;
  mallId = NaN;
  mallInfo: any = {};
  cRegionStatus = false;
  // 区域日期组件状态 v-show      --start
  dType = 'd';
  rType = 'r';
  wType = 'xw';
  mType = 'xm';
  dateWrapStatus = true;
  dayShow = false;
  rangeShow = true;
  weekShow = false;
  monthShow = false;
  dateTypeShow = true;
  durationObj: any = {
    d: '日',
    xw: '周',
    xm: '月',
    r: '范围',
  };
  tempMaxDate = '';
  tempMinDate = '';
  forceUpdate = false;
  // 区域日期组件状态 v-show      -- end
  // 区域日期组件options         -- start
  dPickerOptions = {
    disabledDate: (date: Date): number => this.checkoutServiceDate(date),
    firstDayOfWeek: 1,
  };
  // 时间范围组件: 最大允许选择31天
  rangePickerOptions = {
    disabledDate: (date: Date): number => {
      const dates = this.$dayjs(date).format('YYYYMMDD');
      let isChecked = this.checkoutServiceDate(date);
      if (isChecked === 0 && this.tempMinDate && this.tempMaxDate) {
        // 时间范围组件：最大允许选择31天
        if (dates > this.tempMaxDate || dates < this.tempMinDate) {
          isChecked = 1;
        }
      }
      return isChecked;
    },
    onPick: (selectDate: any) => {
      const { maxDate, minDate } = selectDate;
      if (minDate && !maxDate) {
        this.tempMinDate = this.$dayjs(minDate).subtract(30, 'day')
          .format('YYYYMMDD');
        this.tempMaxDate = this.$dayjs(minDate).add(30, 'day')
          .format('YYYYMMDD');
      } else {
        this.tempMaxDate = '';
        this.tempMinDate = '';
      }
    },
    firstDayOfWeek: 1,
  };
  wPickerOptions = {
    disabledDate: (date: Date): number => {
      const dates = this.$dayjs(date).format('YYYYMMDD');
      const maxDate = `${this.$dayjs(initDate('xw')).format('YYYYMMDD')}`;
      let isChecked = 1;
      if (dates > maxDate) {
        return isChecked;
      }
      if (services.mallId) {
        const weekEndDate = endOfWeek(this.$dayjs(date));
        for (const item of services.dates) {
          if (weekEndDate >= item.serviceStart && weekEndDate <= item.serviceEnd) {
            isChecked = 0;
            return isChecked;
          }
        }
        return isChecked;
      }
      return isChecked;
    },
    firstDayOfWeek: 1,
  };
  mPickerOptions = {
    disabledDate: (date: Date): number => {
      const dates = this.$dayjs(date).format('YYYYMMDD');
      const maxDate = this.$dayjs(initDate('xm')).format('YYYYMMDD');
      let isChecked = 1;
      if (dates > maxDate) {
        return isChecked;
      }
      if (services.mallId) {
        const nextDates = this.$dayjs(date)
          .endOf('month')
          .format('YYYYMMDD');
        for (const item of services.dates) {
          if (nextDates >= item.serviceStart && nextDates <= item.serviceEnd) {
            isChecked = 0;
            return isChecked;
          }
        }
      }
      return isChecked;
    },
    firstDayOfWeek: 1,
  };
  // 区域日期组件options         -- end

  // 区域日期组件model           -- start
  dateType = 'd';
  dayDate = '';
  rangeDate: string[] = [];
  weekDate = '';
  monthDate = '';
  date = '';
  dates = '';
  // 区域日期组件model           -- end

  // 周组件                     -- start
  weekStartDate = '';
  weekEndDate = '';
  // 周组件                     -- end
  // 日期组件状态值               -- start
  durationText = '日期';
  compareDuration = '较昨日';
  // 日期组件状态值               -- end
  addAoiRegionShow = false;
  exportShow = false;
  btnWrapStatus = true;
  downloadStatus = true;

  competedModelObj: any = {};
  nName = '';
  routerObj: any = {};
  $store: any;
  paramsInit = 'init2';
  aoiPortraitDate = '2022-01-01';

  dDateInit = initDate(this.dType);
  rDateInit = initDate(this.rType);
  wDateInit = initDate(this.wType);
  mDateInit = initDate(this.mType);
  dateTypeObj = {
    d: {
      type: 'day',
      computed: 1,
      dateInit: this.dDateInit,
      aoiPortraitDate: this.aoiPortraitDate,
    },
    r: {
      type: 'day',
      computed: 1,
      dateInit: this.dDateInit,
      aoiPortraitDate: this.aoiPortraitDate,
    },
    xw: {
      type: 'day',
      computed: 7,
      dateInit: this.wDateInit,
      aoiPortraitDate: this.aoiPortraitDate,
    },
    xm: {
      type: 'month',
      computed: 1,
      dateInit: this.mDateInit,
      aoiPortraitDate: this.aoiPortraitDate,
    },
  };
  beforeDate = '';
  beforeType = '';
  // 路由路径
  mapPath = '/system/intensity/map';
  $beaconAction: any;
  routerIds = '';
  isTestUser = false;

  created(): void {
    this.getStoresUserStatusHandler();
  }
  @Watch('$store.state.global.isChecked', { immediate: false, deep: true })
  checkStatus(): void {
    this.isCheck = this.$store.state.global.isChecked;
  }
  @Watch('$route.path', { immediate: false, deep: true })
  routeName(): void {
    if (!this.$route.path.includes('report/report')) {
      this.$stores.global.checkDetails(false);
    }
  }
  @Watch('$store.state.global.name', { immediate: false, deep: true })
  name(): void {
    this.returnName = this.$store.state.global.name;
  }

  returnHomePage() {
    this.$stores.global.checkDetails(false);
  }
  get addAoi(): number {
    return permissionModule.addAoi;
  }

  get currentDateType(): DateObj {
    return (this.dateTypeObj as any)[this.dateType];
  }

  get addDisabled(): boolean {
    const obj = this.addDate();
    const { disabled } = obj;
    return disabled;
  }

  get subtractDisabled(): boolean {
    const obj = this.subtractDate(false);
    const { disabled } = obj;
    return disabled;
  }

  get exceptionDateStatus(): boolean {
    return this.routerIds === '4-3-1' || this.routerIds === '4-3-2';
  }

  get exceptionExportFile(): boolean {
    return this.routerIds === '4-3-1';
  }

  get regionStatus(): boolean {
    return this.routerIds === '4-3-1';
  }

  subtractDate(flag = false): any {
    const { type: dateTypeStr, computed, aoiPortraitDate } = this.currentDateType;
    const dates = this.$dayjs(this.date).format('YYYYMMDD');
    const startDates = this.$dayjs(aoiPortraitDate).format('YYYYMMDD');
    let date2 = this.date;
    let disabled = false;
    const code = this.exceptionDateStatus;
    let tempDate;
    if (dates === startDates && code) {
      disabled = true;
    } else {
      tempDate = this.$dayjs(this.date).subtract(computed, dateTypeStr)
        .format('YYYY-MM-DD');
    }
    // 需要判断是否在服务期，能否前进或者后退
    if (services.mallId && flag) {
      const d = this.$dayjs(tempDate).format('YYYYMMDD');
      const { dates } = services;
      date2 = this.date;
      if (this.dateType === 'd') {
        for (const item of dates) {
          if (d >= item.serviceStart && d <= item.serviceEnd) {
            date2 = tempDate;
          }
        }
      } else if (this.dateType === 'xw') {
        const sunday = endOfWeek(this.$dayjs(d));
        for (const item of dates) {
          if (sunday >= item.serviceStart && sunday <= item.serviceEnd) {
            date2 = tempDate;
          }
        }
        this.date = date2;
        this.weekDate = date2;
        this.getWeekDate(this.weekDate);
      } else {
        const m = this.$dayjs(d)
          .endOf('month')
          .format('YYYYMMDD');
        for (const item of dates) {
          if (m >= item.serviceStart && m <= item.serviceEnd) {
            date2 = tempDate;
          }
        }
      }
    }
    return { date: date2, disabled };
  }

  addDate(flag = false): any {
    const { type: dateTypeStr, computed, dateInit } = this.currentDateType;
    const dates1 = this.$dayjs(this.date).format('YYYYMMDD');
    const endDates = this.$dayjs(dateInit).format('YYYYMMDD');
    let date2 = this.date;
    let disabled = false;
    const code = this.exceptionDateStatus;
    let tempDate;
    if (dates1 === endDates && code) {
      disabled = true;
    } else {
      tempDate = this.$dayjs(this.date).add(computed, dateTypeStr)
        .format('YYYY-MM-DD');
    }
    // 需要判断是否在服务期，能否前进或者后退
    if (services.mallId && flag) {
      const d = this.$dayjs(tempDate).format('YYYYMMDD');
      const { dates } = services;
      date2 = this.date;
      if (this.dateType === 'd') {
        const maxDate = this.$dayjs(initDate('d')).format('YYYYMMDD');
        for (const item of dates) {
          if (d >= item.serviceStart && d <= item.serviceEnd && d <= maxDate) {
            date2 = tempDate;
          }
        }
      } else if (this.dateType === 'xw') {
        const maxDate = this.$dayjs(initDate('xw')).format('YYYYMMDD');
        const sunday = endOfWeek(this.$dayjs(d));
        for (const item of dates) {
          if (sunday >= item.serviceStart && sunday <= item.serviceEnd && sunday <= maxDate) {
            date2 = tempDate;
          }
        }
        this.date = date2;
        this.weekDate = date2;
        this.getWeekDate(this.weekDate);
      } else {
        const m = this.$dayjs(d).format('YYYYMMDD');
        const nextMonthFirstDay = this.$dayjs(d)
          .endOf('month')
          .format('YYYYMMDD');
        const maxDate = this.$dayjs(initDate('xm')).format('YYYYMMDD');
        for (const item of dates) {
          if (nextMonthFirstDay >= item.serviceStart && nextMonthFirstDay <= item.serviceEnd && m <= maxDate) {
            date2 = tempDate;
          }
        }
      }
    }
    return { date: date2, disabled };
  }

  // stores 本地复用用户使用状态
  getStoresUserStatusHandler(): void {
    // 初始化日期
    const dDate = this.dDateInit;
    const rDate = this.rDateInit;
    const wDate = this.wDateInit;
    const mDate = this.mDateInit;
    // store 相关记录
    let {
      date,
      duration,
      dayDate,
      rangeDate,
      weekDate,
      monthDate,
      dates,
    } = this.$store.state.mall;
    // 异常错误处理
    date = date ? date : dDate;
    dates = dates ? dates : dDate;
    duration = duration ? duration : this.dType;
    dayDate = dayDate ? dayDate : dDate;
    rangeDate = rangeDate ? rangeDate : rDate;
    weekDate = weekDate ? weekDate : wDate;
    monthDate = monthDate ? monthDate : mDate;
    // 公用变量赋值
    this.date = date;
    this.dates = dates;
    this.dateType = duration;
    this.dayDate = dayDate;
    this.rangeDate = rangeDate.split(',');
    this.weekDate = weekDate;
    this.monthDate = monthDate;
    // 周类型日期计算
    if (duration === 'xw') {
      this.weekDate = date;
      this.weekDate = this.$dayjs(this.weekDate).subtract(5, 'day');
    }
    // 范围日期计算
    this.beforeDate = this.date;
    this.beforeType = this.dateType;
    this.getWeekDate(this.weekDate);
    this.getDates(this.dateType);
    this.$stores.mall.setDate(this.date);
    this.$stores.mall.setDuration(this.dateType);
    this.$stores.mall.setDayDate(this.dayDate);
    this.$stores.mall.setRangeDate(this.rangeDate);
    this.$stores.mall.setWeekDate(this.weekDate);
    this.$stores.mall.setMonthDate(this.monthDate);
    this.setStorage();
  }

  // stores 本地复用用户使用状态
  setStoresUserStatusHandler(): void {
    // 初始化日期
    const dDate = this.dDateInit;
    const rDate = this.rDateInit;
    const wDate = this.wDateInit;
    const mDate = this.mDateInit;
    // store 相关记录
    let {
      date,
      duration,
      dayDate,
      rangeDate,
      weekDate,
      monthDate,
      dates,
    } = this.$store.state.mall;

    // if (services.mallId) {
    //   const { serviceStart, serviceEnd } = services;
    //   if (dayDate) {
    //     const dayDate1 = this.$dayjs(dayDate).format('YYYYMMDD');
    //     if (dayDate1 < serviceStart || dayDate1 > serviceEnd) {
    //       dayDate = dDate;
    //       date = dDate;
    //       dates = dDate;
    //     }
    //   }
    //   if (rangeDate) {
    //     const [start, end] = rangeDate.split(',');
    //     const start1 = this.$dayjs(start).format('YYYYMMDD');
    //     const end1 = this.$dayjs(end).format('YYYYMMDD');
    //     if (start1 < serviceStart || end1 > serviceEnd) {
    //       rangeDate = rDate;
    //       date = dDate;
    //       dates = dDate;
    //     }
    //   }

    //   if (weekDate) {
    //     // const dayDate1 = this.$dayjs(wDate).format('YYYYMMDD');
    //     // 默认为测试用户，禁用掉非服务期间数据
    //     let monday = this.$dayjs(weekDate);
    //     if (monday.day() !== 0) {
    //       monday = monday.add(7, 'day');
    //     }
    //     monday = monday.startOf('week')
    //       .add(1, 'day')
    //       .format('YYYYMMDD');
    //     if (monday < services.serviceStart || monday > services.serviceEnd) {
    //       weekDate = wDate;
    //       if (duration === 'xw') {
    //         dates = wDate;
    //         date = wDate;
    //       }
    //     }
    //   }
    //   if (monthDate) {
    //     const m =  this.$dayjs(monthDate).format('YYYYMMDD');
    //     const start = this.$dayjs(services.serviceStart).startOf('month')
    //       .format('YYYYMMDD');
    //     const end = this.$dayjs(services.serviceEnd).startOf('month')
    //       .format('YYYYMMDD');
    //     if (m < start || m >= end) {
    //       monthDate = mDate;
    //       weekDate = wDate;
    //       rangeDate = rDate;
    //       dayDate = dDate;
    //       if (duration === 'xm') {
    //         date = mDate;
    //         dates = mDate;
    //       }
    //     }
    //   }
    // 异常错误处理
    date = date ? date : dDate;
    dates = dates ? dates : dDate;
    duration = duration ? duration : this.dType;
    dayDate = dayDate ? dayDate : dDate;
    rangeDate = rangeDate ? rangeDate : rDate;
    weekDate = weekDate ? weekDate : wDate;
    monthDate = monthDate ? monthDate : mDate;
    // 公用变量赋值
    this.date = date;
    this.dates = dates;
    this.dateType = duration;
    this.dayDate = dayDate;
    this.rangeDate = rangeDate.split(',');
    this.weekDate = weekDate;
    this.monthDate = monthDate;
    // 周类型日期计算
    if (duration === 'xw') {
      this.weekDate = date;
      this.weekDate = this.$dayjs(this.weekDate).subtract(5, 'day');
      dates = wDate;
      date = wDate;
    }
    if (duration === 'xm') {
      date = mDate;
      dates = mDate;
    }
    // 范围日期计算
    this.beforeDate = this.date;
    this.beforeType = this.dateType;
    this.getWeekDate(this.weekDate);
    this.getDates(this.dateType);
    this.$stores.mall.setDate(this.date);
    this.$stores.mall.setDuration(this.dateType);
    this.$stores.mall.setDayDate(this.dayDate);
    this.$stores.mall.setRangeDate(this.rangeDate);
    this.$stores.mall.setWeekDate(this.weekDate);
    this.$stores.mall.setMonthDate(this.monthDate);
    this.setStorage();
    // }
  }

  // 周组件日期生成回调
  getWeekDate(dates: string): void {
    const date = this.$dayjs(dates).format('YYYY-MM-DD');
    const tempDay = Vue.prototype.$dayjs(date);
    const startDate = startOfWeek(tempDay, 'YYYY-MM-DD');
    const endDate = endOfWeek(tempDay, 'YYYY-MM-DD');
    // const newDate = this.$dayjs().format('YYYYMMDD');
    // const endDates = this.$dayjs(endDate).format('YYYYMMDD');
    // const weekDates = weekDateS();
    // if (endDates > newDate) {
    //   startDate = weekDates.startDate;
    //   endDate = weekDates.endDate;
    // }
    this.weekStartDate = startDate;
    this.weekEndDate = endDate;
    // this.date = this.weekEndDate;
  }

  // 日周月公用文案匹配生成回调
  getDates(duration: string): void {
    let dates = this.date;
    let durationText = '日期';
    let compareDuration = '较昨日';
    if (duration === this.wType) {
      dates = `${this.weekStartDate}~${this.weekEndDate}`;
      durationText = '周';
      compareDuration = '较上周';
    } else if (duration === this.mType) {
      const date = `${this.monthDate.slice(0, 7)}月`;
      dates = date.replace('-', '年');
      durationText = '月份';
      compareDuration = '较上月';
    } else if (duration === this.dType) {
      dates = this.date;
      durationText = '日期';
      compareDuration = '较昨日';
    }
    this.dates = dates;
    this.durationText = durationText;
    this.compareDuration = compareDuration;
    this.$stores.mall.setDates(this.dates);
    this.$stores.mall.setDurationText(this.durationText);
    this.$stores.mall.setCompareDuration(this.compareDuration);
    this.setStorage();
  }

  // 主场区域changer事件回调
  async mallChangeHandler(mallInfo: any): Promise<void> {
    const { mall_id: mallId, name } = mallInfo;
    this.mallId = mallId;
    this.mallInfo = mallInfo;
    this.competedDataHandler(this.routerId);
    if (this.paramsInit === 'init') {
      this.$emit('paramsChangeHandler');
    }
    this.paramsInit = 'init';
    const names = this.getRouteName();
    this.buriedPointHandler('region_change', {
      mall: name,
      submenu: names,
    });
    const res: any = await getMallServiceStartAndEnd({ mall_id: mallId });
    if (res.status === 0) {
      services = {
        dates: [],
        mallId,
        isTestUser: false,
      };
      let sum = 0;
      for (const item of res.data) {
        const { service_cycle_start, service_cycle_end } = item;
        const start = new Date(service_cycle_start).getTime();
        const end = new Date(service_cycle_end).getTime();
        const s = (end - start) / 1000 / 60 / 60 / 24;
        services.dates.push({
          serviceStart: this.$dayjs(service_cycle_start).format('YYYYMMDD'),
          serviceEnd: this.$dayjs(service_cycle_end).format('YYYYMMDD'),
          days: s,
        });
        sum += s;
      }
      if (sum < 15) {
        services.isTestUser = true;
      }
      // 切换了mall需要判断勾选的时间是否还在服务期，不在服务期间的话默认给到当前时间
      this.setStoresUserStatusHandler();
      this.forceUpdate = !this.forceUpdate;
      this.$stores.mall.setMallServices(services);
      this.isTestUser = services.isTestUser;
    }
  }

  // 路由变化展示不同的组件并初始化区域相关依赖参数
  containerHideByPath(routerIds: string, routerObj: any): void {
    const routerId = Number(routerIds.split('-')[0]);
    if ((routerIds !== '4-3-1' && routerIds !== '6-2-1' && routerIds !== '6-2-2' && routerIds !== '6-2-3')
      && services.mallId === this.mallId && services.isTestUser && this.dateType === this.mType) {
      this.dateType = this.dType;
    }
    if (routerId === 1) {
      this.weekShow = false;
      this.monthShow = false;
      this.dayShow = false;
      this.rangeShow = true;
      this.dateWrapStatus = true;
      this.dateTypeShow = false;
      this.addAoiRegionShow = false;
      this.exportShow = true;
      const dateType = 'r';
      this.dateTypeChangeHandler2(dateType);
      if (routerIds === '1-3') {
        this.dateWrapStatus = false;
      }
    } else if (
      routerId === 2
      || routerId === 3
      || routerId === 4
      || routerId === 5
    ) {
      this.dateWrapStatus = true;
      this.dateTypeShow = true;
      this.exportShow = true;
      this.dateTypeChangeHandler2(this.dateType);
      if (routerIds === '3-4') {
        this.exportShow = false;
      }
    } else if (routerId === 6) {
      this.dateWrapStatus = true;
      this.exportShow = true;
      if (
        routerIds === '6-2-1'
        || routerIds === '6-2-2'
        || routerIds === '6-2-3'
      ) {
        this.dateTypeShow = false;
        this.dateType = 'xm';
        this.dateTypeChangeHandler2(this.dateType);
      } else {
        this.dateTypeShow = true;
        this.dateTypeChangeHandler2(this.dateType);
      }
      if (routerIds === '6-2-3') {
        this.exportShow = false;
      } else if (routerIds === '6-3') {
        this.dateTypeShow = false;
        this.dateWrapStatus = false;
        this.exportShow = false;
      }
    } else if (routerId === 7) {
      this.btnWrapStatus = false;
      this.cRegionStatus = false;
      this.dateWrapStatus = false;
    } else {
      this.cRegionStatus = false;
      this.btnWrapStatus = true;
      this.cRegionStatus = true;
      this.dateWrapStatus = true;
    }
    this.cRegionStatus = routerId === 6;
    if (this.addAoi === 1 && routerId === 4) {
      this.addAoiRegionShow = true;
    } else {
      this.addAoiRegionShow = false;
    }
    const date = this.$dayjs(this.dayDate).format('YYYYMMDD');
    const aoiPortraitDate = this.$dayjs(this.aoiPortraitDate).format('YYYYMMDD');
    const wDate = this.$dayjs(this.weekDate).add(5, 'day')
      .format('YYYYMMDD');
    const mDate = this.$dayjs(this.monthDate).format('YYYYMMDD');
    if (routerIds === '4-3-1') {
      this.addAoiRegionShow = false;
      this.dateTypeShow = false;
      this.monthShow = true;
      this.weekShow = false;
      this.dayShow = false;
      if (mDate < aoiPortraitDate || this.dateType !== this.mType) {
        const date = initDate(this.mType);
        this.dateType = this.mType;
        this.dateChangeHandler(date, this.mType);
      }
      this.mPickerOptions = this.mDateDisabledHandler(this.aoiPortraitDate);
    } else if (this.routerObj.id === '4-3-1') {
      this.mPickerOptions = this.mDateDisabledHandler('');
    }

    if (routerIds === '4-3-2') {
      this.exportShow = false;
      this.addAoiRegionShow = false;

      if (date < aoiPortraitDate) {
        const date = initDate(this.dType);
        this.dateChangeHandler(date, this.dType);
      }
      if (wDate < aoiPortraitDate) {
        const date = initDate(this.wType);
        this.dateChangeHandler(date, this.wType);
      }
      if (mDate < aoiPortraitDate) {
        const date = initDate(this.mType);
        this.dateChangeHandler(date, this.mType);
      }
      this.dPickerOptions = this.dDateDisabledHandler(this.aoiPortraitDate);
      this.wPickerOptions = this.wDateDisabledHandler(this.aoiPortraitDate);
      this.mPickerOptions = this.mDateDisabledHandler(this.aoiPortraitDate);
    } else if (this.routerObj.id === '4-3-2') {
      this.dPickerOptions = this.dDateDisabledHandler('');
      this.wPickerOptions = this.wDateDisabledHandler('');
      this.mPickerOptions = this.mDateDisabledHandler('');
    }
    this.btnWrapStatus = routerId !== 7;
    this.routerObj = routerObj;
    this.routerIds = routerIds;
    // this.routerId = routerId;
  }

  dDateDisabledHandler(date2: string): any {
    return {
      disabledDate: (date: Date): number => {
        const dates = this.$dayjs(date).format('YYYYMMDD');
        const maxDate = this.$dayjs(initDate('d')).format('YYYYMMDD');
        const maxDate2 = this.$dayjs(date2).format('YYYYMMDD');
        let isChecked = 1;
        if (dates > maxDate) {
          isChecked = 1;
          return isChecked;
        }
        if (date2) {
          if (dates < maxDate2) {
            isChecked = 1;
            return isChecked;
          }
        }
        if (services.mallId) {
          for (const item of services.dates) {
            if (dates >= item.serviceStart && dates <= item.serviceEnd) {
              isChecked = 0;
              return isChecked;
            }
          }
        }
        return isChecked;
      },
      firstDayOfWeek: 1,
    };
  }

  wDateDisabledHandler(date2: string): any {
    return {
      disabledDate: (date: Date): number => {
        const dates = this.$dayjs(date).format('YYYYMMDD');
        const maxDate = `${this.$dayjs(initDate('xw')).format('YYYYMMDD')}`;
        const maxDate2 = this.$dayjs(date2).format('YYYYMMDD');
        let isChecked = 1;
        if (dates > maxDate) {
          isChecked = 1;
          return isChecked;
        }
        if (date2) {
          if (dates < maxDate2) {
            isChecked = 1;
            return isChecked;
          }
        }
        if (services.mallId) {
          const weekEndDate = endOfWeek(this.$dayjs(date));
          for (const item of services.dates) {
            if (weekEndDate >= item.serviceStart && weekEndDate <= item.serviceEnd) {
              isChecked = 0;
              return isChecked;
            }
          }
        }
        return isChecked;
      },
      firstDayOfWeek: 1,
    };
  }

  mDateDisabledHandler(date2: string): any {
    return {
      disabledDate: (date: Date): number => {
        const dates = this.$dayjs(date).format('YYYYMMDD');
        const maxDate = this.$dayjs(initDate('xm')).format('YYYYMMDD');
        const maxDate2 = this.$dayjs(date2).format('YYYYMMDD');
        let isChecked = 1;
        if (dates > maxDate) {
          isChecked = 1;
          return isChecked;
        }
        if (date2) {
          if (dates < maxDate2) {
            isChecked = 1;
            return isChecked;
          }
        }
        if (services.mallId) {
          const nextDates = this.$dayjs(date)
            .endOf('month')
            .format('YYYYMMDD');
          for (const item of services.dates) {
            if (nextDates >= item.serviceStart && nextDates <= item.serviceEnd) {
              isChecked = 0;
              return isChecked;
            }
          }
        }
        return isChecked;
      },
      firstDayOfWeek: 1,
    };
  }

  // 竞品显示&执行回调
  competedDataHandler(routerId: number): void {
    if (Number(routerId) === 6) {
      this.cRegionStatus = true;
    } else {
      this.cRegionStatus = false;
    }
  }

  // 竞品区域change事件回调
  competedModalChange(obj: any, nCount: number): void {
    let { mall_id: nMallId, name: nName, center_gcj: nCenterGcj } = obj;
    nMallId = nMallId ? nMallId : 0;
    nName = nName ? nName : '';
    nCenterGcj = nCenterGcj ? nCenterGcj : '';
    this.nName = nName;
    this.competedModelObj = {
      mMallId: this.mallId,
      nMallId,
      nCount,
      nName,
      nCenterGcj,
    };
    this.$stores.mall.setCompetedModelObj(this.competedModelObj);
    const { path } = this.$route;
    const paths = path.split('/');
    const path2 = paths[2];
    if (nCount === 0 && path2 === 'intensity') {
      this.$router.push(this.mapPath);
      this.containerHideByPath('6-3', this.routerObj);
    } else {
      const { path } = this.$route;
      if (path === this.mapPath) {
        const path: any = sessionStorage.getItem('intensityRouterPath');
        this.$router.push(path);
        this.$nextTick(() => {
          let id: any;
          if ((this.$route.meta as any).id) {
            id = (this.$route.meta as any).id;
          }
          // const { id } = this.$route.meta;
          const obj = {
            id,
            path,
          };
          this.containerHideByPath(id, obj);
        });
      }
    }
  }

  // 日周月change回调
  dateChangeHandler(dates: string, type: string): void {
    let date = this.$dayjs(dates).format('YYYY-MM-DD');
    if (date === this.beforeDate && type === this.beforeType) return console.log('参数重复，请使用不同参数再重试');
    this.beforeDate = date;
    this.beforeType = type;
    if (type === this.dType) {
      this.dayDate = date;
    } else if (type === this.wType) {
      this.weekDate = dates;
      this.getWeekDate(dates);
      date = this.weekEndDate;
    } else if (type === this.mType) {
      this.monthDate = date;
    }
    this.dateType = type;
    this.date = date;
    this.getDates(type);
    this.$stores.mall.setDate(this.date);
    this.$stores.mall.setDayDate(this.dayDate);
    this.$stores.mall.setWeekDate(this.weekDate);
    this.$stores.mall.setMonthDate(this.monthDate);
    this.$stores.mall.setDuration(this.dateType);
    this.setStorage();
    this.$emit('paramsChangeHandler');
    const name = this.getRouteName();
    this.buriedPointHandler('date_change', {
      dateType: this.durationObj[type],
      date,
      submenu: name,
    });
  }

  dateTypeChangeHandler2(type: string): void {
    // this.dateType = type;
    this.weekShow = false;
    this.monthShow = false;
    this.dayShow = false;
    this.rangeShow = false;

    if (type === this.dType) {
      this.dayShow = true;
      this.date = `${this.dayDate}`;
    } else if (type === this.wType) {
      this.weekShow = true;
      this.date = this.weekEndDate;
    } else if (type === this.mType) {
      this.monthShow = true;
      this.date = `${this.monthDate}`;
    } else if (type === this.rType) {
      this.rangeShow = true;
      // this.date = this.rangeDate.join(',');
    }
    this.beforeDate = this.date;
    this.beforeType = type;
    this.getDates(type);
    this.$stores.mall.setDate(this.date);
    this.$stores.mall.setDuration(this.dateType);
    this.$stores.mall.setDayDate(this.dayDate);
    this.$stores.mall.setWeekDate(this.weekDate);
    this.$stores.mall.setMonthDate(this.monthDate);
    this.$stores.mall.setRangeDate(this.rangeDate);
    this.setStorage();
  }

  dateRangeChangeHandler(dateAry: string[], type: string): void {
    const start: string = this.$dayjs(dateAry[0]).format('YYYY-MM-DD');
    const end: string = this.$dayjs(dateAry[1]).format('YYYY-MM-DD');
    const rangeDate = [start, end];
    const date1 = rangeDate.join(',');
    if (date1 === this.beforeDate && type === this.beforeType) return console.log('参数重复，请使用不同参数再重试');
    this.beforeDate = date1;
    this.beforeType = type;
    this.getDates(type);
    this.rangeDate = rangeDate;
    // this.date = date1;
    // this.$stores.mall.setDate(this.date);
    this.$stores.mall.setRangeDate(this.rangeDate);
    this.setStorage();
  }

  setStorage(): void {
    const { state } = this.$store;
    sessionStorage.setItem('store', JSON.stringify(state));
  }

  // 日周月类型change回调
  dateTypeChangeHandler(type: string): void {
    this.dateTypeChangeHandler2(type);
    this.$emit('paramsChangeHandler');
    const name = this.getRouteName();
    this.buriedPointHandler('date_type_change', {
      dateType: this.durationObj[type],
      submenu: name,
    });
  }

  buriedPointHandler(buriedPoint: string, params?: any): void {
    this.$beaconAction.onUserAction(buriedPoint, params);
  }

  // 添加区域回调
  addAoiClickHandler(): void {
    this.$router.push('/manage/source');
  }

  getRouteName(): string {
    const routeParentName = this.$route.matched[1].name;
    const routeName = this.$route.name;
    const name = `${routeParentName}-${routeName}`;
    return name;
  }

  // 导出公共代码
  downloadHandlerExcel(data: any): void {
    const routeParentName = this.$route.matched[1].name;
    const routeName = this.$route.name;
    let fileName = `${routeParentName}-${routeName}-${this.dates}数据导出`;
    // exceptionExportFile
    const { aoiTypeCurrentObj } = this.$store.state.mall;
    if (this.exceptionExportFile) {
      const name2 = aoiTypeCurrentObj.name ? aoiTypeCurrentObj.name : '居住地';
      fileName = `${routeParentName}-${routeName}-${name2}-${this.dates}数据导出`;
    }

    const name = this.getRouteName();
    this.buriedPointHandler('date_change', {
      fileName,
      submenu: name,
    });
    const { path } = this.$route;
    const { mallName, durationText, dates, rangeDate } = this.$store.state.mall;
    const compareMall = `对比竞品:${this.nName}`;
    const dateStr = `${durationText}:${dates}`;


    data.forEach((item: any, index: number) => {
      const mallNames = `区域：${mallName}`;
      if (!data[index].sheetMultiHeader) {
        // eslint-disable-next-line no-param-reassign
        data[index].sheetMultiHeader = [];
      }
      const { sheetMultiHeader } = data[index];
      if (path === '/system/summary/compare') {
        sheetMultiHeader.push([''], ['']);
      } else if (path === '/system/summary/statistics' || path === '/system/summary/analysis') {
        sheetMultiHeader.push([rangeDate.replace(',', '至')], ['']);
      } else {
        sheetMultiHeader.push([dateStr], ['']);
      }

      if (path.includes('/intensity')) {
        sheetMultiHeader.unshift([compareMall]);
      }
      sheetMultiHeader.unshift([mallNames]);
    });
    const jsonData = JSON.stringify(data);
    const d = jsonData.replace(/暂无数据权限/g, '');
    const option = JSON.parse(d);
    if (option) {
      exportJsonDataExcel(option, fileName);
    }
  }

  get downAble(): boolean {
    return this.$store.state.global.downAble;
  }
  // 导出excel
  downloadHandler(): void {
    this.downloadStatus = true;
    this.$stores.global.setDownloadStatus(this.downloadStatus);
    this.$root.$data.event.$off('getDataS');
    this.$root.$data.event.$on('getDataS', (data: any) => {
      this.downloadHandlerExcel(data);
    });
    setTimeout(() => {
      this.downloadStatus = false;
      this.$stores.global.setDownloadStatus(this.downloadStatus);
    }, 20);
  }

  checkoutServiceDate(date: Date) {
    const dates = this.$dayjs(date).format('YYYYMMDD');
    const maxDate = this.$dayjs(initDate('d')).format('YYYYMMDD');
    let isChecked = 1; // 1: 禁用 0: 正常
    // 大于最新日期，直接禁用
    if (dates > maxDate) {
      return isChecked;
    }
    // 禁用掉非服务期间数据
    if (services.mallId) {
      for (const item of services.dates) {
        if (dates >= item.serviceStart && dates <= item.serviceEnd) {
          isChecked = 0;
          return isChecked;
        }
      }
    }
    return isChecked;
  }
}
