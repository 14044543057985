


















/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { getMallByUserId } from '@/services/sourceApi';
type MallObj = {
  value: string;
  label: string;
  children: any;
};

@Component({
  components: {
  },
})

export default class CityRegionList extends Vue {
  mallOptions = [];
  value: string[] = [];
  mallId = 0;
  $stores: any;
  @Prop({
    type: String,
    required: false,
    default: '',
  })
  text!: string;
  $refs: any;
  async mounted(): Promise<any> {
    const mallData = await this.getMallByUserId();
    const { option, value, mallInfo } = mallData;
    this.mallOptions = option;
    this.updateMallInfo(mallInfo);
    this.value = value;
  }

  MallChangeHandler(e: string[]): void {
    const mallInfos = JSON.parse(e[1].split('-')[1]);
    this.updateMallInfo(mallInfos);
  }

  async updateMallInfo(mallInfos: any): Promise<void> {
    const {
      adcode: cityAdcode,
      cityCenter,
    } = mallInfos;
    this.$stores.mall.setCityAdcode(cityAdcode);
    this.$stores.mall.setCityCenter(cityCenter);
    this.$stores.pageConfig.setMallUpdateKey(`aoiMallUpdate${cityAdcode}`);
    console.log(mallInfos, '-');
    const { state } = this.$store;
    sessionStorage.setItem('store', JSON.stringify(state));
  }

  async getMallByUserId(): Promise<any> {
    const res: any = await getMallByUserId();
    const datas: any = Object.values(res.data);
    const mallObj: any = {};
    const initObj: any = datas[0];
    const { cityAdcode } = this.$store.state.mall;
    const adcodes: any = cityAdcode ? cityAdcode : initObj.adcode;
    let mallInfo: any = {};
    datas.forEach((item: any) => {
      const {
        province,
        city,
        adcode,
        city_center: cityCenter,
      } = item;
      if (!mallObj[province]) {
        mallObj[province] = {};
      }
      if (!mallObj[province][city]) {
        mallObj[province][city] = {};
      }
      const itemInfo = {
        adcode,
        province,
        city,
        cityCenter,
      };
      mallObj[province][city] = itemInfo;
      if (adcodes === adcode) {
        mallInfo = itemInfo;
      }
    });
    const mallObjs = [];
    // eslint-disable-next-line
    for (const province in mallObj) {
      const cityItem = mallObj[province];
      const provinceObj: MallObj = {
        value: province,
        label: province,
        children: [],
      };
      // eslint-disable-next-line
      for (const city in cityItem) {
        const malls = cityItem[city];
        const malls2 = JSON.stringify(malls);
        const cityObj: MallObj = {
          value: `${cityItem[city].adcode}-${malls2}`,
          label: city,
          children: [],
        };
        provinceObj.children.push(cityObj);
      }
      mallObjs.push(provinceObj);
    }
    const { province, adcode } = mallInfo;
    const mall2 = JSON.stringify(mallInfo);
    const value = [province, `${adcode}-${mall2}`];
    return {
      option: mallObjs,
      value,
      mallInfo,
    };
  }

  @Watch('$route')
  changeRouter(): void{
    this.$refs.regionSelect.visible = false;
  }
}
